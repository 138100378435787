import React from "react";
import "../assets/css/portfolio.css";

const FilterButton = ({ category, isActive, onClick }) => {
  return (
    <button
      className={`filter-btn ${isActive ? "active" : ""}`}
      onClick={() => onClick(category)}
    >
      {category}
    </button>
  );
};

export default FilterButton;
