import React, { useState } from "react";
import PortfolioItem from "./PortfolioItem";
import FilterButton from "./FilterButton";
import "../assets/css/portfolio.css";

const projects = [
  {
    name: "Paint Store Landing Page ",
    category: "Landing Page",
    image: require("../assets/images/house_store.png"),
    url: "https://thriving-flan-c97c99.netlify.app/",
    techStack: "JavaScript, HTML, CSS",
  },
  {
    name: "Number Guesser Website",
    category: "JavaScript",
    image: require("../assets/images/Number Guesser.png"),
    url: "https://sparkling-cheesecake-7f63b2.netlify.app/",
    techStack: "JavaScript, HTML, CSS",
  },
  {
    name: "Project 8",
    category: "JavaScript",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  {
    name: "Project 3",
    category: "Python",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  {
    name: "Project 9",
    category: "Python",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  {
    name: "Project 10",
    category: "Python",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  {
    name: "Project 4",
    category: "Fullstack Academy Bootcamp",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  {
    name: "Project 5",
    category: "Fullstack Academy Bootcamp",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  {
    name: "Project 6",
    category: "Fullstack Academy Bootcamp",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  {
    name: "Project 7",
    category: "Fullstack Academy Bootcamp",
    image: require("../assets/images/loading.png"),
    url: "https://example.com/project2",
    techStack: "React, JavaScript",
  },
  // Add more projects here
];

const categories = [
  "All",
  "Landing Page",
  "JavaScript",
  "Python",
  "Fullstack Academy Bootcamp",
];

const Portfolio = () => {
  const [activeCategory, setActiveCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const filteredProjects =
    activeCategory === "All"
      ? projects
      : projects.filter((project) => project.category === activeCategory);

  return (
    <div className="portfolio" id="portfolio">
      <div className="filters">
        {categories.map((category) => (
          <FilterButton
            key={category}
            category={category}
            isActive={category === activeCategory}
            onClick={handleCategoryClick}
          />
        ))}
      </div>
      <div className="portfolio-items">
        {filteredProjects.map((project) => (
          <PortfolioItem key={project.name} project={project} />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
