import React from "react";
import "../assets/css/portfolio.css";

const PortfolioItem = ({ project }) => {
  console.log(project);
  return (
    <a
      href={project.url}
      className="portfolio-item"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={project.image} alt={project.name} />
      <div className="overlay">
        <div className="project-name">{project.name}</div>
      </div>
      <div className="tech-stack">
        <p>Tech Stack</p>
        <p className="tech-list">{project.techStack}</p>
      </div>
    </a>
  );
};

export default PortfolioItem;
