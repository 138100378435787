import React from "react";
import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import AboutMe from "./components/AboutMe";
import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";
import "./assets/css/starting.css";

const App = () => {
  return (
    <div className="app-container">
      <Navbar />
      <main className="main-content">
        <Banner />
        <AboutMe />
        <Portfolio />
      </main>
      <Footer />
    </div>
  );
};

export default App;
