import React from "react";
import "../assets/css/starting.css";
import "../assets/css/style.css";

const Navbar = () => {
  return (
    <div className="navigation">
      <ul>
        <a className="nav-link" href="#home">
          Home |
        </a>
        <a className="nav-link" href="#about-me">
          About Me
        </a>
        <a className="nav-link logo">Bienvenidos</a>
        <a className="nav-link" href="#portfolio">
          Portfolio |
        </a>
        <a className="nav-link" href="#contact">
          Contact
        </a>
      </ul>
    </div>
  );
};

export default Navbar;
