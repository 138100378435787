import React from "react";
import "../assets/css/starting.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer" id="contact">
        <h2>Contact Me</h2>
        <div className="contact-icons">
          <a href="mailto:a.rgonzalez0951@gmail.com" className="contact-icon">
            <i className="fas fa-envelope"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/a-gonzo/"
            className="contact-icon"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://github.com/GlepZorg" className="contact-icon">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
