import React from "react";
import "../assets/css/starting.css";
import "../assets/css/style.css";
import userImage from "../assets/images/user.png";

const Banner = () => {
  return (
    <div className="banner" id="home">
      <div className="banner-text">
        <h1>Hello, I&rsquo;m</h1>
        <h2>Alexis Gonzalez</h2>
        <button className="btn-main">
          <a href="#contact">Hire Me</a>
        </button>
        <button className="btn-secondary">
          <a href="#portfolio">View My Portfolio</a>
        </button>
      </div>
      <div className="banner-image">
        <img src={userImage} alt="Alexis Gonzalez" />
      </div>
    </div>
  );
};

export default Banner;
