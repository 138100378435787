import React from "react";
import "../assets/css/starting.css";
import "../assets/css/style.css";

const AboutMe = () => {
  return (
    <div className="content" id="about-me">
      <h2>About Me</h2>
      <div className="description">
        <p>
          I am a dynamic developer from the state of California, fueled by an
          insatiable passion for crafting awe-inspiring websites. I'm a Navy
          veteran, that is now embarking on an exciting journey into the tech
          world. My transition into tech started with NPower's IT Fundamentals
          program, where I earned certifications in ITF+ and the Google IT
          Support Certificate. My passion for technology continues to drive me,
          pushing me to explore new avenues and break into this dynamic field.
          <br />
          To further my tech career, I am pursuing a degree in computer science
          and graduated from Full-stack Academy boot camp. This program provided
          me with focused and practical tech skills, introduced me to essential
          tools and concepts, and presented a clear path to becoming a
          proficient software engineer. I thrive on solving complex technical
          challenges, whether it&rsquo;s installing, configuring, or
          troubleshooting hardware and software components, or creating and
          deploying full-stack applications. <br /> One notable project I worked
          on is the Event Management System, where I served as the Backend
          Developer. In this project, I developed a comprehensive backend system
          for event creation, user authentication, and interaction. I developed
          RESTful APIs using Express.js and Prisma ORM with PostgreSQL, enabling
          CRUD operations for user and event management. I implemented JWT
          authentication, OTP-based password reset, and bcrypt for secure access
          and password hashing. Additionally, I integrated geocoding with
          OpenStreetMap API. I ensured data integrity with cascading deletes and
          updates in Prisma models and configured environment variables for
          secure handling of sensitive information. You can find more details on
          my GitHub Repo.
          <br /> Beyond my technical skills, I have a solid background in
          leading security operations and training programs, ensuring compliance
          with state and federal regulations. I'm genuinely excited about the
          endless possibilities the tech sector offers, and my ultimate goal is
          to contribute to these innovations and leave a lasting impact. As I
          evolve as a software engineer, I eagerly anticipate embracing new
          challenges, harnessing my passion, and leveraging my diverse
          background to push the boundaries of what's possible.
          <br /> If you share my enthusiasm for technology or are interested in
          collaborating on innovative projects, please feel free to reach out.
          Let's connect and explore the potential for working together to create
          impactful software solutions.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
